import React, { useState, useEffect } from 'react';
import './LoginPage.css'
import logimg from '../../assets/Flogo.webp'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBRow,
    MDBCol,
    MDBInput
} from 'mdb-react-ui-kit';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();


    useEffect(() => {
        // Check if the user is already logged in
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/admin');
        }
    }, [navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
                email,
                password,
            });

            // If the request is successful, response.data will contain the server response
            const { token } = response.data; // Assuming your API response structure

            if (token) {
                localStorage.setItem('token', token); // Store the token
                navigate('/admin'); // Redirect to admin page
            } else {
                setError('كلمة السر او البريد غير صحيح'); // Error message for failed login
            }
        } catch (err) {
            // Handle Axios error, which contains the error response from the server
            if (err.response && err.response.data) {
                // Extract error message from the server response if available
                setError(err.response.data.message || 'Login failed. Please try again.');
            } else {
                // General error message
                setError('An error occurred. Please try again.');
            }
            console.error('Error details:', err);
        }
    };


    return (
        <div id='Login'>
            <MDBContainer className="my-5">
                <MDBCard className='log-bg'>
                    <MDBRow className='g-0'>
                        <MDBCol md='5'>
                            <Link to="/">
                                <MDBCardImage src={logimg} alt="login form" className='rounded-start w-80' />
                            </Link>
                        </MDBCol>
                        <MDBCol md='6' className='LogForm'>
                            <MDBCardBody className='d-flex flex-column justify-content-center align-items-center w-100'>
                                <h5 className="fw-normal my-4 pb-3" style={{ letterSpacing: '1px' }}>تسجيل دخول</h5>

                                <form onSubmit={handleLogin}>
                                    <MDBInput
                                        wrapperClass='mb-4'
                                        placeholder='البريد الالكتروني'
                                        id='formControlLg'
                                        type='email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        size="lg"
                                    />
                                    <MDBInput
                                        wrapperClass='mb-4'
                                        placeholder='كلمة المرور'
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        id='formControlLg1'
                                        type='password'
                                        size="lg"
                                    />

                                    <MDBBtn className="mb-4 px-5" color='dark' type="submit" size='lg'>تسجيل دخول</MDBBtn>
                                </form>

                                {error && <p className="text-danger">{error}</p>}

                            </MDBCardBody>
                        </MDBCol>
                    </MDBRow>
                </MDBCard>
            </MDBContainer>
        </div>
    );
};

export default LoginPage;
