import React, { useEffect, useState } from 'react'
import './header.css'
import Sicons from './Icon'
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import logo from '../../assets/nav-logo.webp'
import slider1 from '../../assets/showcase-1.webp'
import slider2 from '../../assets/showcase-2.webp'


export default function Header() {
    const [addBlur, setAddBlur] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setAddBlur(window.innerWidth <= 768);
        };

        // Initial check on mount
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div>
            <section id='showcase'>
                <div >
                    <Carousel data-bs-theme="white H-50">
                        <Carousel.Item>
                            <div className={addBlur ? ' Cbg blur' : 'Cbg'}>
                                <img
                                    className="d-block w-100"
                                    src={slider1}
                                    alt="First slide"
                                />
                            </div>
                            <Carousel.Caption className='slider-content'>
                                <img src={logo} alt="logo" />
                                <h5> <strong> مرحبا بكم بشركة إبراهيم الزهراني وأحمد المالكي </strong> <br /> محامون | مستشارون | محكمون <br />نحمي الحقوق والمصالح بالمعايير المهنية بكل إحترافية وأمانة</h5>
                            </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item>
                            <div className={addBlur ? ' Cbg blur' : 'Cbg'}>
                                <img
                                    className="d-block w-100"
                                    src={slider2}
                                    alt="Second slide"
                                />
                            </div>
                            <Carousel.Caption className='slider-content'>
                                <img src={logo} alt="logo" />
                                <h5> <strong> مرحبا بكم بشركة إبراهيم الزهراني وأحمد المالكي </strong> <br /> محامون | مستشارون | محكمون <br />الوجهة الموثوقة لخدمات قانونية ذات جودة عالية</h5>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </div>

                <Sicons />
            </section>
        </div>
    );
};
