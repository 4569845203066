import React from 'react'
import './Footer.css';
import { IoIosPhonePortrait } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { GoClock } from "react-icons/go";
import Flogo from '../../assets/Flogo.webp'
import { Link as LinkS } from 'react-scroll';
import { Link } from 'react-router-dom';


function PageFooter() {

    return (
        <div>
            <footer id='footer'>
                <div className="footer">
                    <div className="Fmap column"
                    >
                        <iframe
                            title="contact"
                            src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d3710.1497191174603!2d39.16637840075357!3d21
                            .580078189482855!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDM0JzUzLjciTiAzOcKwMDknNTguNyJF!5e0!3m2!1sen!2seg!4v1713736348097!5m2!1sen!2seg"
                            allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div id='footerone' className="column"
                        dir='rtl'>
                        <h3>تواصل معنا </h3>
                        <div className="bord phone">
                            <i className='phone'><IoIosPhonePortrait /></i>
                            <div className='bord-cont'>
                                <p className='H'> <strong> الهاتف</strong></p>
                                <p dir='ltr'><a href="tel:+966551155707">+966551155707</a></p>
                            </div>

                        </div>
                        <div className="bord mail">
                            <i className='mail'><MdEmail /></i>
                            <div className='bord-cont'>
                                <p>البريد الإلكتروني</p>
                                <p> <a href="mailto:info@i-a-lawco.com">info@i-a-lawco.com</a></p>
                            </div>

                        </div>
                        <div className="bord address">
                            <i className='address'><FaMapMarkerAlt /></i>
                            <div className='bord-cont'>
                                <p>العنوان</p>
                                <p>المملكة العربية السعودية - جدة - مبنى حسن سكوير - الدور الأول مكتب 110</p>
                            </div>

                        </div>
                        <div className="bord time">
                            <i className='time'><GoClock /></i>
                            <div className='bord-cont'>
                                <p>مواعيد العمل</p>
                                <p>من 9 صباحا حتى 9 مساء ماعدا السبت والجمعة</p>
                            </div>
                        </div>
                    </div>
                    <div id='footertow' className="column"
                        dir='rtl'>
                        <h3>روابط تهمك</h3>
                        <ul>
                            <li><Link to="/">الرئيسية </Link></li>
                            <div className='under-border'></div>
                            <li> <LinkS to="contact">تواصل معنا</LinkS></li>
                            <div className='under-border'></div>
                            <li><Link to="/blog">المدونة</Link></li>
                            <div className='under-border'></div>
                        </ul>
                    </div>
                    <div className="column C"
                        data-aos-offset="100"
                        data-aos-duration="1500">
                        <div className='Fbord'>
                            <img src={Flogo} alt="img" />
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div className='footer-end'>
                    <h4><span><a href="https://missanvip.com/"> Missan</a> </span> تصميم وتطوير </h4>
                </div>
            </footer >

        </div>
    )
}

export default PageFooter