import React, { useState, useEffect, useRef } from 'react';
import './AdminPage.css';
import { Toast } from 'primereact/toast';

import { Link, useNavigate } from 'react-router-dom';

import upimg from "../../assets/upload_image.webp";
import { BarLoader } from 'react-spinners';
// Import React Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const customColors = [
    '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff',
    '#facccc', '#ffebcc', '#ffffcc', '#ccffcc', '#cceeff', '#ebd6ff', '#bbbbbb', '#f06666',
    '#ffc266', '#ffff66', '#66ff66', '#66ffff', '#c2a3ff', '#666666', '#a10000', '#b26b00',
    '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600',
    '#003700', '#002966', '#3d1466', '#175541', '#C79149'
];

const modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        [
            { 'color': customColors },
            { 'background': customColors }
        ], // Custom colors for text and background
        [{ 'align': [] }],
        ['link', 'image', 'video'],
        ['clean'] // Remove formatting button
    ],
};

// Include 'color' and 'background' in formats
const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'color', 'background', 'align',
    'link', 'image', 'video'
];

const AdminPage = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const toast = useRef(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
        }

    }, [navigate]);

    const addBlog = async () => {
        // Input validations
        if (!title) {
            setError('عنوان المدونة مفقود');
            toast.current.show({ severity: 'warn', summary: 'تحذير', detail: 'يرجى كتابة عنوان المدونة', life: 3000 });
            return;
        }
        if (!content) {
            setError('محتوي المدونة مفقود');
            toast.current.show({ severity: 'warn', summary: 'تحذير', detail: 'يرجى كتابة محتوي المدونة', life: 3000 });
            return;
        }
        if (!image) {
            toast.current.show({ severity: 'warn', summary: 'تحذير', detail: 'يرجى إختيار صورة المدونة', life: 3000 });
            return;
        }

        setLoading(true);
        setError('');

        try {
            // Create FormData to send as request body
            const formData = new FormData();
            formData.append('title', title);
            formData.append('content', content);
            formData.append('image', image); // Append image file

            // Make the API call to add the blog
            await axios.post(`${process.env.REACT_APP_API_URL}/api/blogs/add`, formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`, // Include token for authentication
                    'Content-Type': 'multipart/form-data', // Set content type for file upload
                },
            });


            // Reset form fields after successful addition
            setTitle('');
            setContent('');
            setImage(null);
            setImagePreview('');


            toast.current.show({ severity: 'success', summary: 'تم', detail: `تم إضافة المدونة نجاح`, life: 3000 });
            navigate('/admin'); // Redirect to admin page or another relevant page

        } catch (error) {
            setError('خطأ في إضافة المدونة'); // Error message
            toast.current.show({ severity: 'error', summary: 'خطأ', detail: 'خطأ في إضافة المدونة', life: 3000 });
        } finally {
            setLoading(false);
        }
    };

    const handleImageChange = (e) => {
        if (e.target.files.length > 0) {
            const selectedImage = e.target.files[0];
            setImage(selectedImage);
            setImagePreview(URL.createObjectURL(selectedImage));
        }
    };

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (


        <div id='add-blog'>
            <Toast ref={toast} />
            <div className='add-blog'>
                <div className='Eblog-head'>
                    <div className='Eblog-head1'>
                        <button onClick={handleLogout}>تسجيل خروج</button>
                        <Link to='/' dir='rtl'>الرئيسية</Link>
                        <Link to='/existing-blogs'> المدونات</Link>
                    </div>
                    <h2>أضف مدونة</h2>
                </div>

                <div className='add-board' dir='rtl'>
                    <p>عنوان المدونة</p>
                    <input
                        type="text"
                        placeholder="عنوان المدونة"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <p>محتوي المدونة</p>

                    {/* React Quill Editor */}
                    <div style={{ height: "100%", width: "80%", backgroundColor: "#FFF", marginBottom: "1rem" }}>
                        <ReactQuill
                            value={content}
                            onChange={setContent}
                            modules={modules}
                            formats={formats}
                            placeholder="اكتب محتوى المدونة هنا..."
                            style={{ height: "60vh", backgroundColor: "#FFF", marginBottom: "1rem" }}
                        />
                    </div>

                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                    />
                    <div className='add-board-bot'>
                        <button onClick={addBlog} disabled={loading}>أضف المدونة +</button>
                        <div className="image-preview" onClick={handleImageClick} style={{ cursor: 'pointer' }}>
                            {imagePreview ? (
                                <img src={imagePreview} alt="Selected" style={{ width: '200px', height: 'auto' }} />
                            ) : (
                                <img src={upimg} alt="Placeholder" style={{ width: '200px', height: 'auto' }} />
                            )}
                        </div>
                    </div>

                    {error && <p className="error-message">{error}</p>}
                    {loading && <BarLoader color="#C79149" />}
                </div>
            </div>
        </div>
    );
};

export default AdminPage;
