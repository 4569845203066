import React from 'react'
import './Services.css'
import logo from "../../assets/nav-logo.webp"
import card1 from '../../assets/card-1.webp'
import card2 from '../../assets/card-2.webp'
import card3 from '../../assets/card-3.webp'
import card4 from '../../assets/card-4.webp'
import card5 from '../../assets/card-5.webp'
import card6 from '../../assets/card-6.webp'
import card7 from '../../assets/card-7.webp'
import card8 from '../../assets/card-8.webp'
import card9 from '../../assets/card-9.webp'
import card10 from '../../assets/card-10.webp'
import card11 from '../../assets/card-11.webp'
import card12 from '../../assets/card-12.webp'



function Services() {

    const ServicesData = [
        {
            id: 1,
            name: "إعداد وصياغة العقود والاتفاقيات",
            img: card1,
            description: "نقدم مراجعة العقود وصياغتها، الاتفاقيات ومذكرات التفاهم مع مراعاة المعايير المطلوبة لرغبة عملائنا",
        },
        {
            id: 2,
            name: "التقاضي",
            img: card2,
            description: "لدينا فريق مؤهل وخبير في تمثيل موكلينا أمام مختلف المحاكم وديوان المظالم واللجان القضائية وجهات التحقيق داخل المملكة وخارجها.",
        },
        {
            id: 3,
            name: "الاستشارات الشرعية والقانونية",
            img: card3,
            description: "تقديم المشورة والنصيحة الشرعية والقانونية، وتحليلها ودراستها مع بيان نقاط القوة وتقديم الحلول المناسبة.",
        },
        {
            id: 4,
            name: "الحوكمة والامتثال القانوني",
            img: card4,
            description: "ضمان العمليات المبدئية والالتزام بقيم الشركات والممارسات التجارية والتأكد من الامتثال لها",
        },
        {
            id: 5,
            name: "التحكيم",
            img: card5,
            description: "لدينا خبرة في إدارة وتنظيم التحكيم حيث تتم الإجراءات بجلسات غير علنية",
        },
        {
            id: 6,
            name: "التجارة والإفلاس",
            img: card6,
            description: "إعداد عقود تأسيس الشركات وإنهاء كافة الإجراءات، ومعرفة حالة العميل لتحديد الإجراء المناسب له بالتصفية أو إعادة التنظيم المالي",
        },
        {
            id: 7,
            name: "المنازعات العقارية",
            img: card7,
            description: "خبرة في المنازعات العقارية المتعلقة بالصكوك والعقود العقارية السكنية والتجارية، والتطوير العقاري وصناديق الاستثمار العقاري.",
        },
        {
            id: 8,
            name: "البنوك والتمويل والتأمين",
            img: card8,
            description: "تقديم خدمات للبنوك وشركات التمويل والتأمين في صياغة العقود وتقليل المخاطر وتحصيل الديون المتعثرة",
        },
        {
            id: 9,
            name: "الاستثمار الأجنبي",
            img: card9,
            description: "تقديم خدمات تأسيس الشركات الأجنبية ومتابعة إصدار التراخيص والسجلات التجارية وتمثيل المستثمر الأجنبي أمام الجهات الرسمية.",
        },
        {
            id: 10,
            name: "الأوقاف والنظارة والوصايا",
            img: card10,
            description: "خبرة في القضايا الوقفية مثل عزل الناظر ومحاسبته، وصياغة الوقفية والوصايا وإثباتها أمام المحكمة واستكمال تراخيص الوقفية.",
        },
        {
            id: 11,
            name: "الملكية الفكرية",
            img: card11,
            description: "تقديم خدمات حفظ الحقوق والحماية الفكرية للعلامات التجارية والاختراعات وتسجيلها",
        },
        {
            id: 12,
            name: "تصفية التركات",
            img: card12,
            description: "بدءاً من استخراج صك حصر الورثة إلى تقسيم التركة شرعياً سواءً كانت رضائية أو قضائية مع تحديد الأنصبة والحصص",
        },
    ]
    return (
        <div>
            <section id="serv">
                <div className="sec-border"
                >
                    <div className="about-container">
                        <h1 ><span>خدماتنا</span></h1>
                        <div className="serv-container">
                            {ServicesData.map((Scard) => (
                                <div key={Scard.id} className="serv-card" >
                                    <div className='hover-bg'>
                                        <a
                                            href="https://api.whatsapp.com/send?phone=966551155707"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <div className='hover-div'>
                                                <img src={logo} width={"60px"} alt="" />
                                                <br />
                                                <p>استشر الآن</p>
                                            </div>
                                        </a>
                                    </div>
                                    <img src={Scard.img} alt="img" />
                                    <div className='card-conrent'>
                                        <h5>{Scard.name}</h5>
                                        <p>{Scard.description}</p>

                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services