import React, { useEffect } from 'react'
import Nav from '../components/Nav/Nav'
import About from '../components/About-us/About'
import Contact from '../components/Contact/Contact'
import Footer from '../components/Footer/Footer'
import Services from '../components/Services/Services'
import './Home.css'
import Header from '../components/header/Header'
import Rblog from '../components/Rblog/Rblog'
import { Helmet } from 'react-helmet-async'

const HomePage = () => {

    const baseUrl = window.location.origin;
    const imageUrl = `${baseUrl}/Home.jpg`;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <main >
            <Helmet>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="description" content="شركة إبراهيم الزهراني وأحمد المالكي للمحاماة هي شركة قانونية سعودية مهنية تم تأسيسها على معايير المهنية الاحترافية العالية والالتزام بالقيم الإسلامية والأنظمة المرعية. نؤمن بأن العدالة هي أساس الاستقرار والنمو في المجتمع ، ونسعى لتحقيق هذا الهدف من خلال تقديم خدمات قانونية شاملة ومتنوعة تناسب احتياجات عملائنا ضمن ايطار الاحترافية . مع فريق من المحامين ذوي الخبرة الواسعة والمعرفة المتعمقة بالقوانين المحلية والدولية، نعمل جاهدين لنكون شركاء موثوقين لعملائنا في مسيرتهم نحو تحقيق العدالة والنجاح." />
                <title>شركة ابراهيم الزهراني واحمد المالكي للمحاماة والاستشارات القانونية</title>
                <link rel="icon" href="./favicon.ico" type="image/x-icon" />
                <link rel="canonical" href="https://i-a-lawco.com/" />
                <link rel="icon" type="image/png" sizes="16x16" href="./favicon-16x16.webp" />
                <link rel="icon" type="image/png" sizes="32x32" href="./favicon-32x32.webp" />
                <link rel="apple-touch-icon" href="./apple-touch-icon.webp" />
                <link rel="icon" type="image/png" sizes="192x192" href="./android-chrome-192x192.webp" />
                <link rel="icon" type="image/png" sizes="512x512" href="./android-chrome-512x512.webp" />
                <link rel="manifest" href="./site.webmanifest" />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="شركة ابراهيم الزهراني واحمد المالكي للمحاماة والاستشارات القانونية" />
                <meta property="og:description" content="شركة إبراهيم الزهراني وأحمد المالكي للمحاماة هي شركة قانونية سعودية مهنية تم تأسيسها على معايير المهنية الاحترافية العالية والالتزام بالقيم الإسلامية والأنظمة المرعية. نؤمن بأن العدالة هي أساس الاستقرار والنمو في المجتمع ، ونسعى لتحقيق هذا الهدف من خلال تقديم خدمات قانونية شاملة ومتنوعة تناسب احتياجات عملائنا ضمن ايطار الاحترافية . مع فريق من المحامين ذوي الخبرة الواسعة والمعرفة المتعمقة بالقوانين المحلية والدولية، نعمل جاهدين لنكون شركاء موثوقين لعملائنا في مسيرتهم نحو تحقيق العدالة والنجاح." />
                <meta property="og:image" content={imageUrl} />
                <meta property="og:url" content={baseUrl} />
                <meta property="og:type" content="website" />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="شركة ابراهيم الزهراني واحمد المالكي للمحاماة والاستشارات القانونية" />
                <meta name="twitter:description" content="شركة إبراهيم الزهراني وأحمد المالكي للمحاماة هي شركة قانونية سعودية مهنية تم تأسيسها على معايير المهنية الاحترافية العالية والالتزام بالقيم الإسلامية والأنظمة المرعية. نؤمن بأن العدالة هي أساس الاستقرار والنمو في المجتمع ، ونسعى لتحقيق هذا الهدف من خلال تقديم خدمات قانونية شاملة ومتنوعة تناسب احتياجات عملائنا ضمن ايطار الاحترافية . مع فريق من المحامين ذوي الخبرة الواسعة والمعرفة المتعمقة بالقوانين المحلية والدولية، نعمل جاهدين لنكون شركاء موثوقين لعملائنا في مسيرتهم نحو تحقيق العدالة والنجاح." />
                <meta name="twitter:image" content={imageUrl} />
                <meta name="twitter:site" content="@Mohamed12_98" />
                <meta name="twitter:creator" content="@Mohamed12_98" />
            </Helmet>


            <Nav />
            <Header />
            <div className='main-bg-container'>
                <div className='main-bg'>
                    <About />
                    <Services />
                    <Contact />
                    <Rblog />
                </div>
            </div>
            <Footer />
        </main>
    );
}

export default HomePage